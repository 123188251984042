<template>
<div class="search" >
  <Head @searchDate = searchDate ></Head>
  <div style="min-height:92.9vh" v-if="trades.length!==0||schArts.length!==0||questions.length!==0">
<!-- 问答交流 -->

   <div class="main" v-if="questions.length !==0" >
            <div class="titles">
              <h1>问答交流</h1>
            </div>
            <div class="queInfo"
             v-for="(item,index) in questions" :key="index"
              @click="$router.push('/question/'+item.id,{params:{queId:item.id}})"
             >
            <div class="top">
            <div class="title">
                <p>{{item.title}} <span v-show="item.isdown===0?false:true">已下架</span></p>
                <!-- <p>￥<span>{{item.price}}</span></p> -->
            </div>
            <div class="data">
                <p><span>发表时间:</span>{{item.pubdata | dataFormat}}</p>
            </div>
        </div>
        <div class="content">
            <!-- <p >{{item.content}}</p> -->
        </div>
        <div class="info">
            <div class="views">
                <ul>
                     <li><i class="view-icon"></i><span>{{item.view}}</span></li>

                </ul>
            </div>
            <div class="edit">
                <ul>
                    <li @click="$router.push('/question/'+item.id,{params:{queId:item.id}})">查看详情</li>

                </ul>

            </div>
        </div>
        </div>
    </div>

 <!-- 校园公告 -->
   <div class="main"   v-if="schArts.length !==0">
            <div class="titles">
              <h1>校园公告</h1>
            </div>
            <div class="queInfo"
             v-for="(item,index) in schArts" :key="index"
             @click="$router.push('/schArt/'+item.id,{params:{schArtId:item.id}})"
             >
            <div class="top">
            <div class="title">
                <h3>{{item.title}} <span v-show="item.isdown===0?false:true">已下架</span></h3>
                <!-- <p>￥<span>{{item.price}}</span></p> -->
            </div>
            <div class="data">
                <p><span>发表时间:</span>{{item.pubdata | dataFormat}}</p>
            </div>
        </div>
        <div class="content">
            <!-- <p >{{item.content}}</p> -->
        </div>
        <div class="info">
            <div class="views">
                <ul>
                     <li><i class="view-icon"></i><span>{{item.view}}</span></li>

                </ul>
            </div>
            <div class="edit">
                <ul>
                    <li @click="$router.push('/schArt/'+item.id,{params:{schArtId:item.id}})">查看详情</li>

                </ul>

            </div>
        </div>
        </div>
    </div>

  <!-- 商品部分 -->
     <div  class="main_shop"  v-if="trades.length !==0" >
        <div class="titles">
              <h1>二手商品</h1>

            </div>
      <div class="lists">
        <ul>
          <li v-for="(item,index) in trades" :key="index">
            <div class="img" @mouseenter="imgUp" @mouseleave="imgDown" @click="$router.push('/trade/'+item.id,{params:{traId:item.id}})">
               <img :src="item.photo" ref="shopImg" v-if="item.photo" alt="">
               <img src="../../public/images/toux.jpg" v-else alt="">
            </div>
            <div class="introduce">
              <p><span>电子</span>{{item.title}}</p>

            </div>
            <div class="pri">
              <p><span>￥</span>{{item.price}}</p>
              <div class="imm" @click="$router.push('/trade/'+item.id,{params:{traId:item.id}})" >
                立即查看
              </div>
            </div>
          </li>
        </ul>

      </div>

    </div>
  </div>
<el-empty class="el-empty" v-if="trades.length===0&&schArts.length===0&&questions.length===0" :image-size="200"></el-empty>
      <Foot ></Foot>

</div>
</template>

<script>
import Head from '../components/head.vue'
import { search } from '../API/search.js'
import Foot from '../views/foot.vue'
export default {
  name: 'search',
  data () {
    return {
      trades: [],
      schArts: [],
      questions: []
    }
  },
  methods: {
    async  Search () {
      const { data: res } = await search(this.$route.query.keyword)
      // 用户和管理员只能查看正常的公告
      // console.log(res)
      this.schArts = res.data[0].filter(item => {
        return item.isdelete === 0 && item.isdown === 0
      })
      this.trades = res.data[1].filter(item => {
        return item.isdelete === 0 && item.isdown === 0
      })
      this.questions = res.data[2].filter(item => {
        return item.isdelete === 0 && item.isdown === 0
      })
      // console.log(this.schArts)
    },
    imgUp (e) {
      // console.log(e)
      this.$refs.shopImg.style = 'transform: translateY(-15px);'
    },
    imgDown (e) {
      // console.log(e + '1')
      this.$refs.shopImg.style = 'transform: translateY(0px);'
    },
    searchDate (data) {
      this.trades = data.trades
      this.schArts = data.schArts
      this.questions = data.questions
    }
  },
  components: {
    Head,
    Foot
  },
  created () {
    this.Search()
  }
}
</script>

<style lang="less" scoped>
li{
    list-style: none;
}
a{
    text-decoration: none;
}
// 校园公告

.main{
  width: 90%;
  margin: 30px auto;
  .titles{
    width: 95%;
    margin:20px auto;
    display: flex;
    padding: 15px 0;
    border-bottom: 2px solid #757373;
    justify-content: space-between;
    h1{
      font-size: 22px;

      color: #1456af;
    }
    a{
      font-size: 14px;
      color:#757373 ;
    }
    a:hover{
      color: #e71616;
    }
  }
 .queInfo{
    overflow-x: hidden;
    background-color:#fff ;
    border-bottom: 1px solid #dbdbdf;
    cursor: pointer;
    .top{
        display: flex;
        justify-content: space-between;
        padding: 5px 25px;
        .title{
            display: flex;
            align-items: center;
            p{
                margin: 0 20px;
                color: #FF3535;
                font-size: 24px;
                span{

                }
            }
            h3{
                span{
                    color: #d47171;
                }
            }
        }
        .data{
            min-width: 142px;
            text-align: right;
            height: 24px;
            line-height: 24px;
        }
    }
    .content{
         padding: 15px 25px;
         p{
          // width: 430px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-height: 30px;
          -webkit-box-orient: vertical;
          text-indent: 12px;
         }
    }
    .info{
        display: flex;
        padding: 5px 20px;
        justify-content: space-between;
        .views{
            ul{
                display: flex;
                padding: 0;
                 li{
                    margin: 0 5px;
                     i{
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        vertical-align: bottom;
                        margin-right: 8px;
                        background-size: 16px!important;
                    }
                    .view-icon{
                        background: url('../../public/images/浏览.png') no-repeat;
                    }
                    .like-icon{
                        background: url('../../public/images/点赞.png');
                    }
                    .comment-icon{
                        background: url('../../public/images/消息.png');
                    }
                }
            }
        }
        .edit{
            ul{
                display: flex;
                li{
                    margin: 0 5px;
                    cursor: pointer;
                }
               li:hover{

                   color: #ff3535;
               }
            }
        }
    }
  }
 .queInfo:hover{
  background-color: #f3f3f3;
 }
}

// 商品css
.main_shop{
  width: 80%;
  margin: 30px auto;
  .titles{
    width: 95%;
    margin:20px auto;
    display: flex;
    padding: 15px 0;
    border-bottom: 2px solid #757373;
    justify-content: space-between;

    h1{
      font-size: 20px;

        color:  #1456af;
    }
    a{
      font-size: 14px;
      color:#757373 ;
    }
    a:hover{
      color: #e71616;
    }
  }
  .lists{
    width: 100%;

    ul{
      display: flex;
      width: 100%;
      flex-flow:wrap;
      li{
        height: 100%;
        width: 23%;
        margin:  5px;
        background-color: #fff;
        .img{
          width: 100%;
          height: 196px;
          padding: 10px;
          cursor: pointer;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          img{
            width: 80%;
            height: 90%;
          }
        }
        .introduce{
          width: 90%;
          margin: 10px auto;

         p{
           margin: 20px 0 10px 0;
           font-size: 16px;
           span{
             display: inline-block;
             padding: 1px 5px;
             border: 1px solid #ff3535;
             color:#ff3535;
             margin: 0 5px;
            font-size: 12px;
           }
          }
        }
        .pri{
          display: flex;
          margin: 20px 0;
          justify-content: space-around;
          p{
            font-size: 20px;
            color:#ff3535;
            line-height: 40px;
            span{
              font-size: 16px;
            }
          }
          .imm{
              color: #fff;
              height: 35px;
              line-height: 35px;
              font-size: 14px;
              display: block;
              cursor: pointer;
              margin: auto 0;
              width: 75px;
              text-align: center;
              background: #df0021;

          }
          .imm:hover{
             color:rgba(255, 255, 255, .9)
          }
        }
      }
    }
  }
 .bottom{
  margin: 20px 0 ;
  p{
    text-align: center;
    font-size: 24px;
    color:#969090
  }
 }
}
.el-empty{
  height: 84.1vh;
}
</style>
